import Network from '@/services/network'
import {ref} from "vue";

export default function handleHospital() {
    const network = new Network;
    const chartLoading = ref(false)

    // date of barth convert to age
    const dateOfBarthConvertToAge = (birthday) => {
        const birthDateObj = new Date(birthday);
        const currentDate = new Date();

        const timeDifference = currentDate - birthDateObj;

        const year = Math.floor(timeDifference / (365.25 * 24 * 60 * 60 * 1000));
        const month = Math.floor(
            (timeDifference % (365.25 * 24 * 60 * 60 * 1000)) / (30.44 * 24 * 60 * 60 * 1000)
        );
        const day = Math.floor(
            (timeDifference % (30.44 * 24 * 60 * 60 * 1000)) / (24 * 60 * 60 * 1000)
        );

        return {year, month, day}
    }

    // convert age to Y, M, D
    const calculateAge = (birthday) => {
        var date = '';
        const {year, month, day} = dateOfBarthConvertToAge(birthday)

        if(year) {
            date += `${year}Y `;
        }
        if(month) {
            date += `${month}M `;
        }
        if(day) {
            date += `${day}D`;
        }

        return date
    }

    const capitalize = (text) => {
        if(!text || typeof text !== 'string') {
            return text;
        }

        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    const convertHeightToFeetAndInches = (heightInCm) => {
        const totalInches = heightInCm / 2.54;
        const feet = Math.floor(totalInches / 12);
        const inches = Math.floor(totalInches % 12);
    
        return { feet, inches };
    }

    const bmiCalculator = (heightInCm, weightInKg) => {
        return heightInCm > 0 && weightInKg > 0 && ((weightInKg * 10000) / (heightInCm * heightInCm)).toFixed(2);
    }

    //Service Resources Group
    const fetchGroup = (companyId, resourceType) => {
        return network.api('get', 'hospital/service-resources/groups?company_id=' + companyId + '&is_flatten=1&resource_type=' + resourceType);
    }
    const storeGroup = (data) => {
        return network.api('post', '/hospital/service-resources/groups', data);
    }
    const updateGroup = (data) => {
        return network.api('put', '/hospital/service-resources/groups/' + data.id, data);
    }

    // Service Resource
    const fetchServiceResourceList = (query) => {
        return network.api('get', '/hospital/service-resources/list' + query);
    }
    const fetchServiceList = (query, type) => {
        if(type){
            query += `&resource_type=${type}`;
        }
        return network.api('get', '/hospital/service-resources/get-services' + query);
    }
    const fetchServiceResources = (query) => {
        return network.api('get', '/hospital/service-resources' + query);
    }
    const storeServiceResource = (data) => {
        return network.api('post', '/hospital/service-resources', data);
    }
    const updateServiceResource = (data) => {
        return network.api('put', '/hospital/service-resources/' + data.id, data);
    }

    //Patient
    const fetchPatientsList = (query) => {
        return network.api('get', '/hospital/patients/list' + query);
    }
    const fetchPatientDetails = (query, id) => {
        return network.api('get', '/hospital/patients/details/' + id + query);
    }
    const fetchPatientDetailsBySerial = (query, id) => {
        return network.api('get', '/hospital/patients/details-by-serial/' + id + query);
    }

    //Note
    const fetchNoteTypes = (query) => {
        return network.api('get', '/hospital/note-masters/types' + query);
    }
    const fetchNoteMasterList = (query) => {
        return network.api('get', '/hospital/note-masters' + query);
    }
    const storeNote = (data) => {
        return network.api('post', '/hospital/note-masters', data);
    }
    const updateNote = (data) => {
        return network.api('put', '/hospital/note-masters/' + data.id, data);
    }
    const notesByType = (query, type) => {
        return network.api('get', '/hospital/note-masters/notes-by-type/' + type + query);
    }
    const fetchPrescriptionNotes = (query) => {
        return network.api('get', '/hospital/note-masters/prescription-notes/' + query);
    }

    //IPD-Regsiter 
    const fetchIPDRegistrationList = (query) => {
        return network.api('get', '/hospital/ipd-registers' + query);
    }
    const storeRegistration = (data) => {
        return network.api('post', '/hospital/ipd-registers', data);
    }
    const updateRegistration = (data, id) => {
        return network.api('put', '/hospital/ipd-registers/' + id, data);
    }
    const fetchAdmittedByUserList = (query, type = 'patient') => {
        query += `&type=${type}`;
        return network.api('get', '/hospital/ipd-registers/contact-profile-user' + query);
    }
    const fetchDepartList = (query) => {
        return network.api('get', '/hospital/ipd-registers/department-list' + query);
    }
    const fetchAdmissionNoteList = (query) => {
        return network.api('get', '/hospital/ipd-registers/admission-notes' + query);
    }
    const fetchSingleNote = (query, id) => {
        return network.api('get', '/hospital/ipd-registers/' + id + query);
    }
    const fetchIpdRegStatus = (query) => {
        return network.api('get', '/hospital/ipd-registers/ipd-registration-status' + query);
    }
    const fetchIpdRegNumbers = (query) => {
        return network.api('get', '/hospital/ipd-registers/ipd-admission-numbers' + query);
    }
    const fetchIpdStatement = (query) => {
        return network.api('get', '/hospital/report/ipd-statement' + query);
    }

    //Prescription
    const fetchSinglePrescription = (query, id, prescriptionType) => {
        return network.api('get', `/hospital/prescriptions/show-prescription/${id}/${prescriptionType}` + query);
    }
    const storePrescription = (data) => {
        return network.api('post', '/hospital/prescriptions/store-prescription', data);
    }
    const updatePrescription = (data, id) => {
        return network.api('put', '/hospital/prescriptions/update-prescription/' + id, data);
    }
    const fetchIpdStatementDetails = (id, productId, query) => {
        return network.api('get', `/hospital/report/ipd-statement-details/${id}/product/${productId}` + query)
    }
    const storePrescriptionTemplate = (data, doctorOrderId) => {
        return network.api('post', `/hospital/prescriptions/save-as-template/${doctorOrderId}`, data);
    }
    const updateTemplateStatus = (data, doctorOrderId) => {
        return network.api('put', `/hospital/prescriptions/update-template-status/${doctorOrderId}`, data);
    }
    const fetchPrescriptionTemplates = (query) => {
        return network.api('get', `/hospital/prescriptions/prescription-templates` + query);
    }
    const fetchTemplatePrescription = (query, doctorOrderId) => {
        return network.api('get', `/hospital/prescriptions/template-prescription/${doctorOrderId}` + query);
    }

    //Doctor-order
    const fetchSingleDoctorOrder = (query, id, prescriptionType) => {
        return network.api('get', `/hospital/doctor-orders/show-general-order/${id}/${prescriptionType}`+ query);
    }
    const fetchSingleDischargeOrder = (query, id, prescriptionType) => {
        return network.api('get', `/hospital/doctor-orders/show-discharge-order/${id}/${prescriptionType}`+ query);
    }

    //Ot-orders 
    const fetchOtOrderTypes = (query) => {
        return network.api('get', '/hospital/ot-orders/ot-types' + query);
    }
    const storeOtOrder = (data) => {
        return network.api('post', '/hospital/ot-orders/store-ot-order', data);
    }
    const updateOtOrder = (data, id) => {
        return network.api('put', '/hospital/ot-orders/update-ot-order/' + id, data);
    }
    const fetchOtOrderList = (query) => {
        return network.api('get', '/hospital/ot-orders/list' + query);
    }
    const fetchSingleOtOrder = (query, id) => {
        return network.api('get', '/hospital/ot-orders/show/' + id + query);
    }

    //Baby notes 
    const fetchInitialBabyNoteData = (query) => {
        return network.api('get', '/hospital/baby-notes/initial-data' + query);
    }
    const storeBabyNote = (data) => {
        return network.api('post', '/hospital/baby-notes/store-baby-note', data);
    }
    const updateBabyNote = (data, id) => {
        return network.api('put', '/hospital/baby-notes/update-baby-note/' + id, data);
    }
    const fetchBabyNoteList = (query) => {
        return network.api('get', '/hospital/baby-notes/list' + query);
    }
    const fetchSingleBabyNote = (query, id) => {
        return network.api('get', '/hospital/baby-notes/show/' + id + query);
    }
    
    //Investigation-head
    const fetchInvestigationHeads = (query) => {
        return network.api('get', '/hospital/investigation-heads/all' + query);
    }
    const fetchInvestigationHeadTypes = (query) => {
        return network.api('get', '/hospital/investigation-heads/get-types' + query);
    }
    const fetchDischargeStatus = (query) => {
        return network.api('get', '/hospital/investigation-heads/discharge-statuses' + query);
    }
    const fetchInvestigationHeadList = (query) => {
        return network.api('get', '/hospital/investigation-heads' + query);
    }
    const fetchInvestigationHeadGroup = (companyId, locationType) => {
        return network.api('get', '/hospital/investigation-heads/investigation-groups?company_id='
                                  + companyId
                                  + '&is_flatten=1&location_type='
                                  + locationType);
    }
    const storeInvestigationHeadGroup = (data) => {
        return network.api('post', '/hospital/investigation-heads/investigation-groups', data);
    }
    const updateInvestigationHeadGroup = (data) => {
        return network.api('post', '/hospital/investigation-heads/investigation-groups/' + data.id, data);
    }
    const storeInvestigationHead = (data) => {
        return network.api('post', '/hospital/investigation-heads', data);
    }
    const updateInvestigationHead = (data) => {
        return network.api('post', '/hospital/investigation-heads/update-investigation', data);
    }

    //investigation-generals
    const fetchInvestigationGeneral = (query, billId) => {
        return network.api('get', '/hospital/investigation-generals/reports/' + billId + query);
    }
    const fetchInvestigationGeneralReports = (query, billId) => {
        return network.api('get', '/hospital/public/investigation-generals/reports/' + billId + query);
    }
    const fetchSaleGeneral = (query, id) => {
        return network.api('get', '/hospital/investigation-generals/show-sale-generals/' + id + query);
    }
    const fetchSaleGeneralDetails = (query, id) => {
        return network.api('get', '/hospital/public/investigation-generals/show-sale-generals/' + id + query);
    }
    const updateInvestigationGeneral = (data) => {
        return network.api('put', '/hospital/investigation-generals/update-investigation-general', data);
    }
    const saveInvestigationAttachment = (data, query) => {
        return network.api('post', '/hospital/investigation-generals/store-investigation-attachments' + query, data);
    }

    // service resource
    const fetchServiceResource = (id, query) => {
        return network.api('get', `/hospital/service-resources/${id}` + query);
    }

    // settlement handler
    const storeSettlement = (id, data) => {
        return network.api('post', `/hospital/ipd-statement/${id}/settlement`, data);
    }

    // settlement update andler
    const updateSettlement = (id, data) => {
        return network.api('put', `/hospital/ipd-statement/${id}/settlement`, data);
    }

    // receipt ledger generals
    const receiptLedgerGenerals = (id, query) => {
        return network.api('get', `/hospital/receipt-master/${id}/ledger-generals${query}`);
    }

    // Pharmacy
    const fetchReorderReport = (query) => {
        return network.api('get', '/hospital/pharmacy/reorder-reports' + query);
    }
    const fetchManufacturer = (query) => {
        return network.api('get', '/hospital/pharmacy/manufacturers' + query);
    }
    const fetchExpiredProductReport = (query) => {
        return network.api('get', '/hospital/pharmacy/expired-products-reports' + query);
    }

    // Billing PDF Info
    const checkBillingInfo = (data) => {
        return network.api('post', `/hospital/public/billings/check-billing-info`, data);
    }

    return {
        dateOfBarthConvertToAge,
        chartLoading,
        calculateAge,
        capitalize,

        fetchGroup,
        storeGroup,
        updateGroup,

        fetchServiceResourceList,
        fetchServiceResources,
        storeServiceResource,
        updateServiceResource,

        fetchPatientsList,
        fetchPatientDetails,
        fetchPatientDetailsBySerial,

        fetchNoteMasterList,
        storeNote,
        updateNote,
        fetchNoteTypes,
        notesByType,
        fetchPrescriptionNotes,

        //IPD-Register
        fetchIPDRegistrationList,
        storeRegistration,
        updateRegistration,
        fetchServiceList,
        fetchAdmittedByUserList,
        fetchDepartList,
        fetchAdmissionNoteList,
        fetchSingleNote,
        fetchIpdRegStatus,
        fetchIpdRegNumbers,
        fetchIpdStatement,
        fetchIpdStatementDetails,
        storePrescriptionTemplate,
        updateTemplateStatus,
        fetchPrescriptionTemplates,
        fetchTemplatePrescription,

        //Ot-Order
        fetchOtOrderTypes,
        storeOtOrder,
        updateOtOrder,
        fetchOtOrderList,
        fetchSingleOtOrder,

        //Ot-Order
        fetchInitialBabyNoteData,
        storeBabyNote,
        updateBabyNote,
        fetchBabyNoteList,
        fetchSingleBabyNote,

        //Prescription
        fetchSinglePrescription,
        storePrescription,
        updatePrescription,
        convertHeightToFeetAndInches,
        bmiCalculator,

        //Doctor-order
        fetchSingleDoctorOrder,
        fetchSingleDischargeOrder,

        //Investigation-head
        fetchInvestigationHeads,
        fetchInvestigationHeadTypes,
        fetchDischargeStatus,
        fetchInvestigationHeadList,
        fetchInvestigationHeadGroup,
        storeInvestigationHeadGroup,
        updateInvestigationHeadGroup,
        storeInvestigationHead,
        updateInvestigationHead,

        //Investigation-generals
        fetchInvestigationGeneral,
        fetchInvestigationGeneralReports,
        fetchSaleGeneral,
        fetchSaleGeneralDetails,
        updateInvestigationGeneral,
        saveInvestigationAttachment,

        //Service Resource
        fetchServiceResource,

        storeSettlement,
        updateSettlement,
        receiptLedgerGenerals,

        // Pharmacy
        fetchReorderReport,
        fetchManufacturer,
        fetchExpiredProductReport,

        // CheckBillingInfo
        checkBillingInfo
    }
}
